import React from 'react'

import type {ProductType} from '../../common/types'

import {ShopProductCard} from '../Shop/Component'
import AddOnCTA from '../AddOnCTA'

import styles from './AddOnSlider.less'

export default ({products}: {products: ProductType}) => {
  if (!products) return null

  return (
    <div className={styles.ScrollList}>
      <h4 className={styles.Header}>People also bought</h4>
      {products.map((item, index) =>
        <div className={styles.ScrollListItem} key={item.id || `LoadingCard ${index}`}>
          <ShopProductCard product={item.product} />
          <AddOnCTA item={item} />
        </div>
      )}
    </div>
  )
}
