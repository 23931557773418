// @flow
import React from 'react'
import {useSelector} from 'react-redux'
import {useQuery} from '@apollo/client'

import type {State} from '../../stateType'
import type {BrandType, ShopifyCollectionType, SponsorType, PageSize} from '../../common/types'
import {shopifyCollectionIds, genericItemTypes} from '../../common/constants'
import {itemizeProducts} from '../../common/itemize'
import {brandIds} from '../../common/brands'

import Slider from '../Slider'
import AddOnSlider from '../AddOnSlider'
import Query from '../Query'

import GET_SHOP_COLLECTION from './Query'
import SHOP_SETTINGS from '../Shop/SettingsQuery'

type ShopSliderProps = {
  sponsor?: ?SponsorType,
  collectionId?: ?string,
  title?: ?string,
  slug?: string,
  enableProductCard?: boolean,
}

export default ({sponsor, collectionId, title, slug, enableProductCard}: ShopSliderProps) => {
  const {pageSize, brand, shopLocale} = useSelector(({page, brand, shop}: State) => ({
    pageSize: page.pageSize,
    brand,
    shopLocale: shop.shopLocale,
  }))

  return (
    <Query
      Component={ShopSlider}
      query={GET_SHOP_COLLECTION}
      context={{clientName: 'shopify'}}
      variables={{country: shopLocale, collectionId: collectionId || shopifyCollectionIds.all}}
      fetchPolicy='cache-first'
      props={{
        pageSize,
        brand,
        sponsor,
        title,
        slug,
        enableProductCard,
      }}
    />
  )
}

const ShopSlider = (props: {
  brand: BrandType,
  collection: ShopifyCollectionType,
  loading: boolean,
  pageSize: PageSize,
  slug?: string,
  sponsor?: SponsorType,
  title?: string,
  enableProductCard?: boolean,
}) => {
  const {data} = useQuery(SHOP_SETTINGS)

  const {brand, loading, slug, sponsor, enableProductCard} = props
  const collection = props.collection

  const isFourThree = brand && brand.id === brandIds.fourthree
  if (isFourThree) return null

  if (!loading && !collection) return null

  const shopOffline = data && data.web_settings && data.web_settings.shop_offline
  if (shopOffline) return null

  if (sponsor) return null

  const productFilter = product => {
    if (!product) return false
    if (product.node.tags.includes('Hidden') || product.node.tags.includes('HideFromNavigationCollections')) return false
    if (slug && product.node.handle === slug) return false

    // include the product if it hasn't been removed by any of the above conditions
    return true
  }

  const products = collection ?
    itemizeProducts(
      collection.products.edges.filter(productFilter),
      genericItemTypes.product
    ) :
    [false, false, false, false] // 4x empty loading cards

  const collectionTitle = collection && collection.title
  // use the collection title unless it's 'All', replace 'All' with 'Shop'
  const defaultTitle = collectionTitle === 'All' ? 'Shop' : collectionTitle
  // hardcoded title overrides default
  const title = props.title || defaultTitle
  // basket page one click add on items slider
  const isAddOnSlider = collection && collection.id === shopifyCollectionIds.addOns

  if (isAddOnSlider) return <AddOnSlider products={products} />

  return (
    <Slider
      internalLink={'/shop'}
      items={products}
      title={title}
      enableProductCard={enableProductCard}
    />
  )
}
